import React, { Component } from 'react';
import './../../../components/shared-components/scan-sticker';
import moment from 'moment';
import TamperedReasonSelect from '../../common/tampered-reason-select';

class AuditScanSticker extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    const { scanSuccess, scanResponseDataCurated } = this.props;
    return (
      <>
        {
            <div className="ScanSticker">
              {
                scanSuccess && scanResponseDataCurated?.waybillNo
                  ? <div className="scan-box">
                    <div className='flex-box justify-content-space-between'>
                    <ul>
                        {
                          (scanResponseDataCurated?.waybillNo) ? <li>
                            <div className="key">Waybill number</div>
                            <div className="value">{scanResponseDataCurated.waybillNo}</div>
                          </li> : null
                        }
                
                      </ul>
                      <ul>
                        {
                          (true) ? <li>
                            <div className="key">Last attempted at</div>
                            <div className="value">{scanResponseDataCurated.lastAttemptDate || "NA"}</div>
                          </li> : null
                        }
                
                      </ul>
                      <ul>
                        {
                          (true) ? <li>
                            <div className="key">No. of attempts</div>
                            <div className="value">{scanResponseDataCurated.numberOfAttempts}</div>
                          </li> : null
                        }
                
                      </ul>
                    </div>
                    <div className='flex-box justify-content-space-between'>
                    <ul>
                        {
                          (scanResponseDataCurated?.consignmentStatus) ? <li>
                            <div className="key">Current status</div>
                            <div className="value">{scanResponseDataCurated.consignmentStatus}</div>
                          </li> : null
                        }
                
                      </ul>
                      <ul>
                        {
                          (true) ? <li>
                            <div className="key">Is Priority</div>
                            <div className="value">{scanResponseDataCurated.priority || "NA"}</div>
                          </li> : null
                        }
                
                      </ul>
                      <ul>
                        {
                          (true) ? <li>
                            <div className="key">Flow Type</div>
                            <div className="value">{scanResponseDataCurated.flowType}</div>
                          </li> : null
                        }
                
                      </ul>
                    </div>
                  </div>
                  : <div className="scan-box"></div>
              }
            </div>
        }
      </>

    )
  }
}

export default AuditScanSticker;